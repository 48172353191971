/* import web fonts */
@import url('./fonts.css');

/* import the responsive typography classes  */
@import url('./typography.css');
/* import Japanese typography adjustments */
@import url('./typography-jp.css');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* define basic styles for the elements */
@layer base {
  html {
    @apply bg-pageBackground;
    @apply text-textPrimary;
  }

  /* Basic page layout rules */
  html,
  body,
  body #__next {
    height: 100%;
    -webkit-overflow-scrolling: touch;
    /* disable the touch pan to zoom gesture, to avoid users accidentally zooming into the UI
    solution is based on the: https://dev.to/jasperreddin/disabling-viewport-zoom-on-ios-14-web-browsers-l13
    */
    touch-action: pan-y;
    /* use the tabular nums to format all the digits to be equal height */
    font-variant-numeric: tabular-nums;
  }

  button,
  a {
    -webkit-tap-highlight-color: transparent;
  }

  /* Improve readability of underlines in Japanese */
  [lang='ja'] a {
    text-decoration-thickness: 1px;
    text-underline-position: under;
  }

  /* Make links in paragraphs more accessible: add underline and focus outline */
  p a {
    text-decoration: underline;
  }
  p a:focus-visible {
    outline: 4px solid theme('colors.focusOutline');
    outline-offset: 2px;
    border-radius: 2px;
  }

  /* customized checkbox */
  input:checked + .checkmark {
    @apply block;
  }

  /* style the appearance of the keychain icon in the forms */
  input::-webkit-contacts-auto-fill-button,
  input::-webkit-credentials-auto-fill-button {
    @apply bg-textWhite;
  }
  /* hide the native Webkit validation bubble for now */
  ::-webkit-validation-bubble {
    display: none;
  }

  /**
   * Style the appearance of the video player's subtitles
   * Line-height is not properly supported by all browsers, so we just change the font family
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/::cue
   */
  ::cue {
    @apply font-light;
  }

  /**
   * Hack to style the appearance of text tags in the video player's subtitles
   * The subtitles can contain custom class tags like <c.classname>text</c> to style the text. The containing WebVTT
   * file would also contain a style tag like: .classname { font-style: italic; }. There is a bug where this style is
   * not applied to the text because of HLS.js (https://github.com/video-dev/hls.js/issues/4897). So we just apply the
   * style here.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/WebVTT_API
   * @note Firefox does not support ::cue(<selector>) so it does not work there
   * @todo Find a more permanent solution based where WebVTT styles are applied properly
   */
  ::cue(c[class='font-style_italic']) {
    font-style: italic;
  }

  .page-container {
    @apply flex;
    @apply flex-col;
    height: 100%;
    width: 100%;
  }

  /* make sure the main page element always presses the footer down */
  .page-container main {
    flex-grow: 1;
  }

  /**
   * container grid variables for various screen sizes as defined by Figma spec
   * @see https://www.figma.com/file/LsJBDmBnN0kqsJQkKzTx9f/DGS-Web-Responsive?node-id=105%3A2911
   */
  :root {
    /**
     * The height of 1vh unit is 1% of the viewport height. The value is 1vh by default, but is updated by JavaScript
     * on page load and window resize.
     * @see /src/components/css-variables.tsx
     */
    --vh: 1vh;
    /**
     * The width of the browser’s vertical scrollbar. We need this to correctly calculate the width of the container
     * grid for both cases where the scrollbar is visible and when it is not. The value is 1vh by default, but is updated
     * by JavaScript on page load and window resize.
     * @see /src/components/css-variables.tsx
     */
    --scrollbar-width: 0px;
    /** The horizontal margins for the main container grid. Differs per viewport. */
    --container-margin: 40px;
    /** The width of the grid container based on the total page width minus scrollbar width and horizontal margins */
    --container-width: calc(100vw - var(--scrollbar-width) - (2 * var(--container-margin)));
    /** The spacing between columns in the grid container. Differs per viewport. */
    --gutter-width: 16px;
    /** The amount of available columns in the grid. Differs per viewport. */
    --grid-columns: 6;
    /** The width of the grid column + gutter. */
    --column-plus-gutter-width: calc((var(--container-width) + var(--gutter-width)) / var(--grid-columns));
    /** The height of the meta navigation */
    --meta-height: 36px;
    /** The height of the main navigation */
    --nav-height: 72px;
  }

  @screen md {
    :root {
      --container-margin: 60px;
      --grid-columns: 12;
      --gutter-width: 24px;
      --nav-height: 80px;
    }
  }

  @screen lg {
    :root {
      --container-margin: 68px;
      --gutter-width: 24px;
    }
  }

  @screen xl {
    :root {
      --container-margin: 80px;
      --gutter-width: 32px;
    }
  }

  @screen 2xl {
    :root {
      --container-width: calc(1536px - (80px * 2)); /* make sure container width always allows for margins */
      --container-margin: calc((100vw - var(--scrollbar-width) - var(--container-width)) / 2);
    }
  }

  /* end grid container set up */

  /**
   * Helper CSS class that adds negative container margins to the element.
   * It can be used to stick to the screen edges by breaking out of the default
   * margins the parent grid container has. Available in all responsive variants.
   */
  @layer base {
    .-mx-container {
      margin-left: calc(-1 * var(--container-margin));
      margin-right: calc(-1 * var(--container-margin));
    }

    /* height of the main navigation bar */
    .nav-area {
      height: var(--nav-height);
    }

    /* height of the main content area excluding the nav bar and meta navigation bar */
    .main-area {
      height: calc(100vh - var(--nav-height) - var(--meta-height));
    }

    .offset-header {
      top: calc(var(--nav-height) + var(--meta-height));
    }
  }

  /* forms and inputs, beyond tailwind classes */

  /* Change Autocomplete styles in Webkit browsers */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: rgba(255, 255, 255, 0.05);
    -webkit-text-fill-color: rgba(255, 255, 255, 0.7);
    -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0.05) inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  /* there are no JS events on autofill,
  so we have to simulate the change on the adjacent input label,
  see the styling in the FormInput component */
  input:-webkit-autofill + label,
  input:-webkit-autofill:hover + label,
  input:-webkit-autofill:focus + label,
  textarea:-webkit-autofill + label,
  textarea:-webkit-autofill:hover + label,
  textarea:-webkit-autofill:focus + label,
  select:-webkit-autofill + label,
  select:-webkit-autofill:hover + label,
  select:-webkit-autofill:focus + label {
    @apply -translate-y-3;
    @apply scale-75;
    @apply opacity-50;
  }
}

/*
  the meta navigation used across all DG properties
  the aim is to have zero code dependencies,
  in case we'd share it with other dev teams
*/
.dg-meta {
  background: #0a0f17;
  font-size: 10px;
  line-height: 20px;
  height: var(--meta-height);
  font-family:
    Lemon Sans Next,
    Calibri,
    Roboto,
    San Francisco,
    ui-sans-serif,
    system-ui,
    -apple-system,
    'system-ui',
    sans-serif;
  text-rendering: geometricPrecision;
  padding-left: 11px;
}

html[lang='ja'] .dg-meta {
  font-family:
    Noto Sans Regular JP,
    Lemon Sans Next,
    Calibri,
    Roboto,
    San Francisco,
    ui-sans-serif,
    system-ui,
    -apple-system,
    'system-ui',
    sans-serif;
}

.dg-meta * {
  display: inline-block;
}

.dg-meta p {
  padding: 8px 16px 8px 0px;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0.25px;
  font-weight: 400;
  cursor: default;
  display: none;
}

@media (min-width: 768px) {
  .dg-meta {
    padding-left: 32px;
  }
  .dg-meta p {
    display: inline-block;
  }
}

.dg-meta a {
  display: inline-block;
  padding: 8px 16px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0.5px;
  font-weight: 500;
}

.dg-meta a:hover {
  background-color: #ffffff;
  color: theme('colors.mainBgBlueC2');
}

.dg-meta a.active,
.dg-meta a.active:hover {
  background-color: #182640;
  color: rgba(255, 255, 255, 1);
}

.dg-meta a:focus-visible {
  outline: 4px solid theme('colors.focusOutline');
  outline-offset: -4px;
}
/* end meta navigation */
