/* Set Up Responsive typography for the Japanese Locale using TailwindCSS Utilities */

/* @todo [2025-02-07] Delete this file when we replace all .dg-text-* classes
with .typo-* */

:root:lang(ja) {
  /*
  `auto-phrase` improves the grouping of japanese characters on line breaks
  so that they are not broken up in the middle of a word
  @see https://developer.chrome.com/blog/css-i18n-features
   */
  word-break: auto-phrase;
}

/* Adjust the default font classes for the japanese Language */

/* Medium font classes for Japanese language */
.dg-text-medium-0:lang(ja),
.dg-text-medium-1:lang(ja),
.dg-text-medium-2:lang(ja),
.dg-text-medium-3:lang(ja),
.dg-text-medium-4:lang(ja),
.dg-text-medium-5:lang(ja),
.dg-text-medium-6:lang(ja),
.dg-text-medium-7:lang(ja),
.dg-text-medium-8:lang(ja),
.dg-text-medium-9:lang(ja) {
  @apply font-mediumJp;
  font-weight: 500;
}

/* Regular font classes for Japanese language */
.dg-text-regular-3:lang(ja),
.dg-text-regular-3-uc:lang(ja),
.dg-text-regular-4:lang(ja),
.dg-text-regular-4-uc:lang(ja),
.dg-text-regular-5:lang(ja),
.dg-text-regular-6:lang(ja),
.dg-text-regular-7:lang(ja),
.dg-text-regular-8:lang(ja) {
  @apply font-regularJp;
  font-weight: 400;
}

/* Light font classes for Japanese language */
.dg-text-light-1:lang(ja),
.dg-text-light-2:lang(ja),
.dg-text-light-2-uc:lang(ja),
.dg-text-light-3:lang(ja),
.dg-text-light-4:lang(ja),
.dg-text-light-5:lang(ja) {
  @apply font-lightJp;
  font-weight: 400;
}
