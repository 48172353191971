/* 
*
Lemon Sans Next by supertype
licensed from
https://supertype.de/fonts/lemon-sans-next
*
*/

@font-face {
  font-family: 'Lemon Sans Next';
  font-weight: 400;
  font-display: swap;
  src:
    url(/fonts/LemonSansNext-Regular.woff2) format('woff2'),
    url(/fonts/LemonSansNext-Regular.woff) format('woff');
}
@font-face {
  font-family: 'Lemon Sans Next';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src:
    url(/fonts/LemonSansNext-RegularItalic.woff2) format('woff2'),
    url(/fonts/LemonSansNext-RegularItalic.woff) format('woff');
}

@font-face {
  font-family: 'Lemon Sans Next Medium';
  font-weight: 400;
  font-display: swap;
  src:
    url(/fonts/LemonSansNext-Medium.woff2) format('woff2'),
    url(/fonts/LemonSansNext-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Lemon Sans Next Medium';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src:
    url(/fonts/LemonSansNext-MediumItalic.woff2) format('woff2'),
    url(/fonts/LemonSansNext-MediumItalic.woff) format('woff');
}

@font-face {
  font-family: 'Lemon Sans Next Light';
  font-weight: 400;
  font-display: swap;
  src:
    url(/fonts/LemonSansNext-Light.woff2) format('woff2'),
    url(/fonts/LemonSansNext-Light.woff) format('woff');
}

@font-face {
  font-family: 'Lemon Sans Next Light';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src:
    url(/fonts/LemonSansNext-LightItalic.woff2) format('woff2'),
    url(/fonts/LemonSansNext-LightItalic.woff) format('woff');
}

/* 
  Japanese Fonts
  the reduced version from https://github.com/hiz8/Noto-Sans-CJK-JP.min
 */

@font-face {
  font-family: 'Noto Sans Regular JP';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url(/fonts/NotoSansCJKjp-Regular.min.woff2) format('woff2');
}

@font-face {
  font-family: 'Noto Sans Light JP';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url(/fonts/NotoSansCJKjp-Light.min.woff2) format('woff2');
}

@font-face {
  font-family: 'Noto Sans Bold JP';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url(/fonts/NotoSansCJKjp-Bold.min.woff2) format('woff2');
}
