/* Set Up Responsive typography using TailwindCSS Utilities */

/* @todo [2025-02-07] Delete this file when we replace all .dg-text-* classes
with .typo-* */

/* Medium font classes */
.dg-text-medium-0 {
  @apply font-medium;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.25px;
  text-rendering: geometricPrecision;
}

.dg-text-medium-1 {
  @apply font-medium;
  font-size: 29px;
  line-height: 36px;
  letter-spacing: 0.25px;
  text-rendering: geometricPrecision;
}

.dg-text-medium-2 {
  @apply font-medium;
  font-size: 23px;
  line-height: 30px;
  letter-spacing: 0.25px;
  text-rendering: geometricPrecision;
}

.dg-text-medium-3 {
  @apply font-medium;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  text-rendering: geometricPrecision;
}

.dg-text-medium-4 {
  @apply font-medium;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0;
  text-rendering: geometricPrecision;
}

.dg-text-medium-5 {
  @apply font-medium;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0;
  text-rendering: geometricPrecision;
}

/* Identical definitions. Will be merged in the future. */
.dg-text-medium-6,
.dg-text-medium-7 {
  @apply font-medium;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-rendering: geometricPrecision;
}

.dg-text-medium-8 {
  @apply font-medium;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-rendering: geometricPrecision;
}

.dg-text-medium-9 {
  @apply font-medium;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-rendering: geometricPrecision;
}

@screen md {
  .dg-text-medium-0 {
    font-size: 44px;
    line-height: 44px;
    letter-spacing: 0.25px;
  }

  .dg-text-medium-1 {
    font-size: 29px;
    line-height: 36px;
    letter-spacing: 0.25px;
  }

  .dg-text-medium-2 {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.25px;
  }

  .dg-text-medium-3 {
    font-size: 19px;
    line-height: 26px;
    letter-spacing: 0;
  }

  .dg-text-medium-4 {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .dg-text-medium-5 {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0;
  }

  /* Identical definitions. Will be merged in the future. */
  .dg-text-medium-6,
  .dg-text-medium-7 {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.5px;
  }

  .dg-text-medium-8 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .dg-text-medium-9 {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.25px;
  }
}

@screen 2xl {
  .dg-text-medium-0 {
    font-size: 52px;
    line-height: 52px;
    letter-spacing: 0.25px;
  }

  .dg-text-medium-1 {
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0;
  }

  .dg-text-medium-2 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0;
  }

  .dg-text-medium-3 {
    font-size: 23px;
    line-height: 30px;
    letter-spacing: 0;
  }

  .dg-text-medium-4 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0;
  }

  .dg-text-medium-5 {
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0;
  }

  /* Identical definitions. Will be merged in the future. */
  .dg-text-medium-6,
  .dg-text-medium-7 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0;
  }

  .dg-text-medium-8 {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .dg-text-medium-9 {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.25px;
  }
}

/* Regular font classes */

.dg-text-regular-3 {
  @apply font-regular;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-rendering: geometricPrecision;
}

.dg-text-regular-3-uc {
  @apply font-regular;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
}

.dg-text-regular-4 {
  @apply font-regular;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
  text-rendering: geometricPrecision;
}

.dg-text-regular-4-uc {
  @apply font-regular;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
}

.dg-text-regular-5 {
  @apply font-regular;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-rendering: geometricPrecision;
}

.dg-text-regular-6 {
  @apply font-regular;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-rendering: geometricPrecision;
}

.dg-text-regular-7 {
  @apply font-regular;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-rendering: geometricPrecision;
}

.dg-text-regular-8 {
  @apply font-regular;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  text-rendering: geometricPrecision;
}

@screen md {
  .dg-text-regular-3 {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.5px;
  }

  .dg-text-regular-3-uc {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.5px;
  }

  .dg-text-regular-4 {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.25px;
  }

  .dg-text-regular-4-uc {
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
  }

  .dg-text-regular-5 {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .dg-text-regular-6 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
  }

  .dg-text-regular-7 {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
  }

  .dg-text-regular-8 {
    font-size: 13px;
    line-height: 16px;
  }
}

@screen 2xl {
  .dg-text-regular-3 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }

  .dg-text-regular-3-uc {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }

  .dg-text-regular-4 {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.25px;
  }

  .dg-text-regular-4-uc {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.25px;
  }

  .dg-text-regular-5 {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.5px;
  }

  .dg-text-regular-6 {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .dg-text-regular-8 {
    font-size: 13px;
    line-height: 16px;
  }
}

/* Light font classes */

.dg-text-light-1 {
  @apply font-light;
  font-size: 23px;
  line-height: 30px;
  letter-spacing: 0;
  text-rendering: geometricPrecision;
}

.dg-text-light-2 {
  @apply font-light;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0;
  text-rendering: geometricPrecision;
}

.dg-text-light-2-uc {
  @apply font-light;
  font-size: 25px;
  line-height: 36px;
  letter-spacing: 0;
  text-transform: uppercase;
  text-rendering: geometricPrecision;
}

.dg-text-light-3 {
  @apply font-light;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  text-rendering: geometricPrecision;
}

.dg-text-light-4 {
  @apply font-light;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0;
  text-rendering: geometricPrecision;
}

.dg-text-light-5 {
  @apply font-light;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0;
  text-rendering: geometricPrecision;
}

@screen md {
  .dg-text-light-1 {
    font-size: 23px;
    line-height: 30px;
  }

  .dg-text-light-2 {
    font-size: 32px;
    line-height: 44px;
  }

  .dg-text-light-2-uc {
    font-size: 32px;
    line-height: 44px;
  }

  .dg-text-light-3 {
    font-size: 19px;
    line-height: 26px;
  }

  .dg-text-light-4 {
    font-size: 21px;
    line-height: 30px;
  }

  .dg-text-light-5 {
    font-size: 15px;
    line-height: 22px;
  }
}

@screen 2xl {
  .dg-text-light-1 {
    font-size: 32px;
    line-height: 38px;
  }
  .dg-text-light-2 {
    font-size: 38px;
    line-height: 56px;
  }

  .dg-text-light-2-uc {
    font-size: 38px;
    line-height: 56px;
  }

  .dg-text-light-3 {
    font-size: 23px;
    line-height: 30px;
  }

  .dg-text-light-4 {
    font-size: 26px;
    line-height: 34px;
  }

  .dg-text-light-5 {
    font-size: 20px;
    line-height: 28px;
  }
}
